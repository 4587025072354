import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { getImage } from "gatsby-plugin-image";
import Layout from '../components/Layout';
import useWindowSize from '../hooks/useWindowSize';
import '../style/services.sass';
import PageIntroSection from '../components/PageIntroSection';
import BusinessImpact from '../components/BusinessImpact';
import SalientFeatures from '../components/SalientFeatures';
import VerticalCarousel2 from '../components/VerticalCarousel2';
import VerticalCarousel from '../components/VerticalCarousel';
import UseGoToSection from '../components/UseGoToSection';

// Main Page Component
const SubServicesPageTemplate = ({ pageIntroData, salientData, carouselData, businessImpact}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isCarouselVisible, setIsCarouselVisible] = useState(true);
  const handleFeatureClick = (index) => {
    setCurrentSlide(index);
    setIsCarouselVisible(true);
    const header = document.getElementById('header-navbar');
    const headerHeight = header ? header.offsetHeight : 0;
    const section = document.querySelector('.custom-slider');
    const sectionTop = section.getBoundingClientRect().top + window.scrollY
    window.scrollTo({
      top: sectionTop - headerHeight,
      behavior: 'smooth'
    });
  };
  return (
    <div className="services-page">
      <PageIntroSection pageIntro={pageIntroData} />
      <SalientFeatures title={salientData.title} features={salientData.features} onFeatureClick={handleFeatureClick} />
      {isCarouselVisible && (
      <VerticalCarousel2
      verticalDots={carouselData.verticalDots}
      mainContent={carouselData.content}
      currentSlide={currentSlide}
      onSlideChange={setCurrentSlide}/>
      )}
      <BusinessImpact impact={businessImpact}/>
      <UseGoToSection />
    </div>
)};

SubServicesPageTemplate.propTypes = {
  verticalDots: PropTypes.array,
  content: PropTypes.array,
};


const SubServicesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  const salientData = {
    title: frontmatter.subServices.salientFeatures.title,
    features: frontmatter.subServices.salientFeatures.features.map(feature => ({
      caption: feature.caption,
      index: feature.index,
      image: getImage(feature.image),
      backgroundColor: feature.backgroundColor,
      hoverText: feature.hoverText,
      hoverBackgroundColor: feature.hoverBackgroundColor
    })),
  };

  return (
    <Layout>
       <SubServicesPageTemplate 
      pageIntroData={frontmatter.subServices.heor.pageIntroData}
      salientData={salientData}
      carouselData={frontmatter.subServices.salientAnalytics}
      businessImpact={frontmatter.subServices.businessImpact} 
      />
    </Layout>
  );
};

SubServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default SubServicesPage;

export const pageQuery = graphql`
  query SubServicesPage {
    markdownRemark(frontmatter: { templateKey: { eq: "heor" } }) {
      frontmatter {
        subServices {
          heor {
            pageIntroData {
              desktop {
                title {
                  fragment
                  color
                }
                backgroundVideo
                backgroundImage {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
                description
                buttonLabel
                buttonColor
                buttonPath
                styles {
                  sectionHeight
                  titleFontSize
                  descriptionFontSize
                  contentWidth
                  contentPadding
                  buttonPadding
                }
              }
              mobile {
                title {
                  fragment
                  color
                }
                backgroundImage {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
                description
                buttonLabel
                buttonColor
                buttonPath
                styles {
                  sectionHeight
                  titleFontSize
                  descriptionFontSize
                  descriptionFontWeight
                  descriptionLineHeight
                  contentWidth
                  contentPadding
                }
              }
            }
          }
          salientFeatures {
            title {
              fragment
              color
            }    
            features {
              caption
              index
              image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED width: 50 )
                  }
              }
              backgroundColor
              hoverText
              hoverBackgroundColor
            }
          }
          salientAnalytics {
            verticalDots {
              image {
                childImageSharp {
                  gatsbyImageData(quality: 100 , width: 25 )
                }
              }
              title
              backgroundColor
              hoverBackgroundColor
            }
            content {
              title
              description
              button
              buttonPosition
              top
              left
              screen1 {
                childImageSharp {
                  gatsbyImageData(quality: 100 )
                }
              }
              positionOne
              zIndexOne
              topOne
              leftOne
              screen2 {
                childImageSharp {
                  gatsbyImageData(quality: 100 )
                }
              }
              positionTwo
              zIndexTwo
              topTwo
              leftTwo
              screen3 {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
              positionThree
              zIndexThree
              topThree
              leftThree
              backgroundImage {
                childImageSharp {
                  gatsbyImageData(quality: 100 )
                }
              }
              loopImages {
                img {
                  childImageSharp {
                    gatsbyImageData(quality: 100 )
                  }
                }
              }
            }
          }
          businessImpact {
            title {
              fragment
              color
            }
            description
            hexagonal {
              title
              subDescription
              logo {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
              }
              color
              height
              width
              subWidth
              subHeight
              flexDirection
              textAlign
              position
              bottom
              right
              top
              left
              marginTop
              marginLeft
              marginRight
              marginBottom
              posRight
              posLeft
            }
            hexagonalImg {
              childImageSharp {
                    gatsbyImageData(
                      quality: 100, 
                      height: 95,
                      layout: CONSTRAINED,
                      placeholder: BLURRED,
                      formats: [AUTO, WEBP, AVIF],
                      breakpoints: [320, 480, 768, 1024, 1366, 1600 , 1800])
              }
            }
            position
            hexagonalColor
            fontFamily
            coreValuesList {
              icon {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
              color
              title
              description
            }
          }
        }
      }
    }
  }
`;
